<template>
    <main class='mt-0 main-content main-content-bg'>
        <section>
            <div class="page-header min-vh-75">
                <div class="container">
                    <div class="row">
                        <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
                            <div class="mt-8 card card-plain">
                                <div class="pb-0 card-header text-start">
                                    <h3 class="font-weight-bolder text-primary text-purple text-gradient">Welcome back</h3>
                                    <p class="mb-0">Enter your email and password to sign in</p>
                                    <p v-if="message" class="text-danger pt-4 pb-0 mb-0">{{ message }}</p>
                                </div>
                                <div class="card-body">
                                    <form role="form" method="post" class="text-start" @submit.prevent="submit">
                                        <label>Email</label>
                                        <div class="form-group">
                                            <input
                                                v-model="user.username"
                                                required
                                                type="email"
                                                class="form-control"
                                                placeholder="Email"
                                                autocomplete="off"
                                            />
                                        </div>

										<label>Password</label>
                                        <div class="form-group">
                                            <input
                                                v-model="user.password"
                                                required
                                                type="password"
                                                class="form-control"
                                                placeholder="Password"
                                                autocomplete="off"
                                            />
                                        </div>

										<div class="text-center">
											<soft-button
                                                class="my-4 mb-2"
                                                variant="gradient"
                                                color="primary"
                                                full-width
                                                type="submit"
                                                :disabled="loading"
                                                :loading="loading"
                                            >
                                                Sign in
                                            </soft-button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8">
                                <div class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" :style="{ backgroundImage: `url(${require('@/assets/img/venue-management-software.jpg')})` }"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex'
import SoftInput from '@/components/SoftInput'
import SoftButton from '@/components/SoftButton'

const body = document.getElementsByTagName('body')[0]

export default {
    name: 'Login',

    data() {
        return {
            user: {
                username: null,
                password: null,
            },
            message: null,
            loading: false,
        }
    },

    components: {
        SoftInput,
        SoftButton,
    },

    created() {
        body.classList.remove('bg-gray-100')
    },

    methods: {

        ...mapActions('auth', [
            'signIn',
        ]),

        submit() {
            this.message = null
            this.loading = true

            this.signIn({ ...this.user }).then(() => {
                this.$router.replace({
                    name: 'Dashboard'
                })
            }).catch(({ response }) => {
                this.message = response?.data?.message || 'Something went wrong'
            }).finally(() => {
                this.loading = false
            })
        }
    },

    beforeUnmount() {
        body.classList.add('bg-gray-100')
    },
}
</script>
